body {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'GT-America-Medium';
    src: url("./assets/fonts/GT-America-Standard-Medium.woff2") format('woff2');
}

@font-face {
    font-family: 'GT-America-Regular';
        src: url("./assets/fonts/GT-America-Standard-Regular.woff2") format('woff2');
}

@font-face {
    font-family: 'Helvetica';
    src: url("./assets/fonts/Helvetica.woff2") format('woff2');
}